import { equipmentService } from "@/_services";
import { router } from "@/_helpers";

// const deepClone = require("lodash.clonedeep");
import { cloneDeep } from "lodash";

const state = {
  status: "",
  items: [],
  itemsCount: 0,
  itemsFreshness: 0,
  lastUpdated: null,
  types: [],
  typesFreshness: 0,
  forms: [],
  formsFreshness: 0,
};

const actions = {
  create({ dispatch, commit }, payload) {
    // console.log("[equipments] creating...", payload);
    commit("createRequest");

    return new Promise(function (resolve, reject) {
      equipmentService.create(payload).then(
        (data) => {
          console.log("{Equipments} received data: ", data);
          commit("createSuccess");

          // display success message
          dispatch("alert/success", router.app.$t("equipment.create_message"), {
            root: true,
          });
          return resolve(data);
        },
        (error) => {
          // Using this may raise an error in the FF console because of unhandled exception!
          commit("createFailure", error);
          dispatch("alert/error", router.app.$t(error), { root: true });
          reject(error);
        }
      );
    });
  },

  update({ dispatch, commit }, { equipmentId, payload }) {
    // console.log("[equipments] updating...", equipmentId, payload);
    commit("updateRequest");

    equipmentService.update(equipmentId, payload).then(
      () => {
        commit("updateSuccess", { id: equipmentId, ...payload });

        // display success message
        dispatch("alert/success", router.app.$t("equipment.update_message"), {
          root: true,
        });
      },
      (error) => {
        // Using this may raise an error in the FF console because of unhandled exception!
        commit("updateFailure", error);
        dispatch("alert/error", router.app.$t(error), { root: true });
      }
    );
  },

  getTypes({ commit }) {
    if (state.typesFreshness && state.typesFreshness + 300000 < Date.now()) {
      // console.log("[freshness] - equipments types.");
      return state.items;
    }

    equipmentService.getTypes().then(
      (data) => {
        // console.log("[equipments] Got types:", data);
        commit("typesSuccess", data);
      },
      (error) => {
        commit("getAllFailure", error);
      }
    );
  },

  getForms({ commit }) {
    if (state.formsFreshness && state.formsFreshness + 300000 < Date.now()) {
      // console.log("[freshness] - equipments forms.");
      return state.forms;
    }

    equipmentService.getForms().then(
      (data) => {
        // console.log("[equipments] Got forms:", data);
        commit("formsSuccess", data);
      },
      (error) => {
        commit("getAllFailure", error);
      }
    );
  },

  getAll({ dispatch, commit }, premiseId) {
    commit("getAllRequest");

    equipmentService.getAll({ premiseId: premiseId }).then(
      (data) => {
        commit("getAllSuccess", data.equipments);
        // dispatch("alert/success", router.app.$t("equipments.ok_message"), {
        //   root: true,
        // });
      },
      (error) => {
        commit("getAllFailure", error);
        if (error && error !== "Unauthorized") {
          dispatch("alert/error", error, { root: true });
        } else {
          dispatch("account/userDenied", "Equipments", { root: true });
        }
      }
    );
  },
  setForms({ commit }, forms) {
    commit("formsSuccess", forms);
  },

  clearAll({ commit }) {
    commit("clearAll");
  },
};

const mutations = {
  createRequest(state) {
    state.status = "updating";
  },
  createSuccess(state) {
    state.status = "success";
  },
  createFailure(state) {
    state.status = "error";
  },
  updateRequest(state) {
    state.status = "updating";
  },
  updateSuccess(state, updated) {
    state.status = "success";
    state.lastUpdated = updated;
  },
  updateFailure(state) {
    state.status = "error";
    state.lastUpdated = null;
  },
  clearAll(state) {
    state.status = "";
    state.items = [];
    state.itemsCount = 0;
    state.itemsFreshness = 0;
    state.forms = [];
    state.formsFreshness = 0;
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, items) {
    state.status = "success";
    state.items = items;
    state.itemsCount = items.length;
    state.itemsFreshness = Date.now();
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
  typesSuccess(state, equipmentsTypes) {
    state.types = equipmentsTypes;
    state.typesFreshness = Date.now();
  },
  formsSuccess(state, equipmentsForms) {
    for (const equipment of equipmentsForms) {
      // console.log("Received forms", equipment);
      // Define the M&C equipment type
      equipment.type = "CCVE";

      if (equipment.fields.length === 0) {
        continue;
      }

      // Update the list fields values to make them usable directly in select fields
      // Build an object with value, text and filter, rather than an unnamed array!
      for (const field of equipment.fields) {
        // console.log("Processing field: ", field);
        if (field.values === undefined || field.values.length === 0) {
          // console.log("Skip", field.values);
          continue;
        }

        if ("text" in field.values[0]) {
          // console.log("skip, Already preprocessed");
          continue;
        }

        const values = Array.from(field.values);
        field.values = [];
        for (const value in values) {
          field.values.push({
            value: values[value][1],
            text: values[value][2],
            filter: values[value][0],
          });
        }
      }
    }

    state.forms = equipmentsForms;
    state.formsFreshness = Date.now();
  },
};

const getters = {
  getPossibleCategories: (state) => () => {
    const categories = [];
    state.types.forEach((equipment, index) => {
      if (!categories.find((item) => item.text === equipment.category)) {
        // dirty but working, prevented by the previous if
        if (
          !state.forms.find((eqp) => eqp.equipment_type_id === equipment.id)
        ) {
          return;
        }
        categories.push({ value: equipment.id, text: equipment.category });
      }
    });
    if (categories.length) {
      categories.sort((elem1, elem2) => elem1.text.localeCompare(elem2.text));
    }
    return categories;
  },
  getPossibleEquipments: (state) => (category) => {
    const equipments = [];
    state.types.forEach((equipment, index) => {
      if (!category || (category && equipment.category === category)) {
        // filter out equipment without form.
        if (state.forms.find((eqp) => eqp.equipment_type_id === equipment.id)) {
          equipments.push({ value: equipment.id, text: equipment.name });
        }
      }
    });
    if (equipments.length) {
      equipments.sort((elem1, elem2) => elem1.text.localeCompare(elem2.text));
    }
    return equipments;
  },
  getEquipmentForm: (state) => (equipment_type_id) => {
    let form = state.forms.find(
      (compared_form) => compared_form.equipment_type_id === equipment_type_id
    );
    if (form) {
      // clone the form and should strip the reactiveness
      // prevent modification between equipments
      return cloneDeep(form);
    }
    return null;
  },
  getEquipmentData: (state) => (id) => {
    return state.types.find((form) => form.id === id);
  },
  getCategoryById: (state) => (id) => {
    const item = state.types.find((item) => item.id === id);
    return item ? item.category : null;
  },
  getByID: (state) => (id) => {
    return state.items.find((item) => item.id === id);
  },
};

export const equipments = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
