import { logService } from "@/_services";
import { router } from "@/_helpers";

const state = {
  status: "",
  items: [],
  itemsCount: 0,
  itemsFreshness: 0,
};

const actions = {
  raise(
    { dispatch, commit },
    { equipment, comments, picture, date, urgent, engie }
  ) {
    commit("alertRequest");

    console.log("engiePayload", engie);
    logService.raise(equipment, comments, picture, date, urgent, engie).then(
      (alert) => {
        const alertId = alert.id;
        commit("alertSuccess", {
          id: alertId,
          equipment,
          comments,
          picture,
          urgent,
        });
        setTimeout(() => {
          // display success message after route change completes
          // dispatch("alert/success", router.app.$t("alert.ok_message"), {
          //   root: true,
          // });
        });
      },
      (error) => {
        // Using this may raise an error in the FF console because of unhandled exception!
        commit("alertFailure", error);
        dispatch("alert/error", router.app.$t(error), { root: true });
      }
    );
  },
  getAll({ commit, dispatch }, premiseId) {
    commit("getAllRequest");
    logService.getAll({ premiseId: premiseId }).then(
      (data) => {
        commit("getAllSuccess", data.logs);
        // dispatch("alert/success", router.app.$t("logs.ok_message"), {
        //   root: true,
        // });
      },
      (error) => {
        commit("getAllFailure", error);
        if (error && error !== "Unauthorized") {
          dispatch("alert/error", error, { root: true });
        } else {
          dispatch("account/userDenied", "Logs", { root: true });
        }
      }
    );
  },
  fetching({ commit }) {
    commit("getAllRequest");
  },
  addAll({ commit }, logs) {
    console.log("logs gotten: ", logs);
    commit("getAllSuccess", logs);
  },
  clearAll({ commit }) {
    commit("clearAll");
  },
};

const getters = {
  getById: (state) => (id) => {
    return state.items.find((item) => item.id === id);
  },
};

const mutations = {
  alertRequest(state) {
    state.status = "posting";
  },
  alertSuccess(state, alert) {
    state.status = "success";
    state.lastPosted = alert;
  },
  alertFailure(state) {
    state.status = "error";
    state.lastPosted = null;
  },
  clearAll(state) {
    state.status = "";
    state.items = [];
    state.itemsCount = 0;
    state.itemsFreshness = 0;
  },
  getAllRequest(state) {
    state.status = "loading";
  },
  getAllSuccess(state, items) {
    state.status = "success";
    state.items = items;
    state.itemsCount = items.length;
    state.itemsFreshness = Date.now();
  },
  getAllFailure(state, error) {
    state.status = "error";
    state.error = error;
  },
};

export const logs = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
