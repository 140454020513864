<template>
  <viz-base-tile :id="id" :data="data" :premised="premised">
    <template #name>
      <cite>I am a Equipments widget #{{ id }} : {{ data }}</cite>
    </template>

    <template #body>
      <h4 style="text-align: right; font-size: 1rem">Demande d'intervention</h4>
      <b-card-text>
        <em v-if="equipments.status === 'loading'">
          <font-awesome-icon icon="spinner" size="2x" spin></font-awesome-icon>
          &nbsp; {{ $t("actions.t_loading") }}
        </em>
        <div v-else-if="equipments.status === 'error'" class="m-3 bg-danger">
          <h1>Could not get equipments: {{ equipments.error }}!</h1>
        </div>
        <div v-else-if="equipments.status === 'success' && equipments.items">
          <div v-if="equipments.items.length > 0">
            <b-table
              :id="`table-equipments-${id}`"
              :sticky-header="data.tableHeaderCss"
              :caption="data.tableCaption"
              caption-top
              primary-key="name"
              :per-page="perPage"
              :current-page="currentPage"
              :items="equipments.items"
              :fields="fields"
              :responsive="data.tableResponsive"
              :tbody-tr-class="rowClass"
            >
              <!-- html content for the status -->
              <template #cell(status)="data">
                <StatusBox :state="data.value" ok-icon=""></StatusBox>
              </template>

              <!-- A custom formatted column -->
              <template #cell(name)="data">
                <strong class="text-primary">{{ data.item.type.name }}</strong>
                <span v-if="data.item.location"
                  ><br />
                  (<em> {{ data.item.location }} </em>)
                </span>
              </template>

              <!-- html content for the raise alert button -->
              <template #cell(raise_alert)="row">
                <b-button
                  v-if="row.item.status"
                  size="sm"
                  variant="primary"
                  :title="$t('equipments.alert_message')"
                  @click="alertRaise(row.item)"
                >
                  <font-awesome-icon icon="plus" size="1x"></font-awesome-icon>
                </b-button>
              </template>

              <!-- html content for the show detail button -->
              <template #cell(show_details)="row">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="row.toggleDetails"
                >
                  <font-awesome-icon
                    :icon="row.detailsShowing ? 'caret-up' : 'caret-down'"
                    size="1x"
                  ></font-awesome-icon>
                </b-button>
              </template>

              <!-- html content for the detailed row -->
              <template #row-details="row">
                <b-card>
                  <b-list-group flush>
                    <b-list-group-item
                      v-for="(value, key) in row.item"
                      :key="key"
                    >
                      <b-row>
                        <b-col sm="4" class="text-sm-right"
                          ><b>{{ key }}:</b></b-col
                        >
                        <b-col class="text-sm-left">{{ value }}</b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </template>
            </b-table>

            <b-pagination
              v-if="equipments.items && equipments.items.length > perPage"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              hide-goto-end-buttons
              size="sm"
              :aria-controls="`table-equipments-${id}`"
              align="right"
            ></b-pagination>
          </div>
          <div v-else class="text-warning">
            {{ $t("equipments.no_items") }}
          </div>
        </div>
        <div v-else class="text-warning">No known equipments!</div>
      </b-card-text>

      <!-- Alert form modal -->
      <modal-alert
        :modal-id="alertRaiseInfos.id"
        :modal-size="alertRaiseInfos.size"
        :modal-title="alertRaiseInfos.title"
        :chosen-equipment="selectedEquipment"
        :equipments-list="selectEquipmentsList"
      >
      </modal-alert>
    </template>
  </viz-base-tile>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "VizEquipments",
  components: {
    VizBaseTile: () => import("./BaseTile"),
    ModalAlert: () => import("../ModalAlert"),
    StatusBox: () => import("../StatusBox"),
  },
  props: {
    id: {
      type: String,
      default: "1",
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    premised: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 500,
      // The list of most useful fields
      fields: [
        {
          key: "status",
          label: this.$t("equipments.fields.status"),
        },
        {
          key: "name",
          label: this.$t("equipments.fields.description"),
          sortable: true,
        },
        {
          key: "raise_alert",
          label: this.$t("equipments.fields.alert"),
          // Do not show details - uncomment to add a show details button -)
          // },
          // {
          //   key: "show_details",
          //   label: this.$t("equipments.fields.details")
        },
      ],
      chosenEquipment: null,
      alertRaiseInfos: {
        id: "alert-raise-modal",
        size: "xl",
        equipment: "",
        title: "",
      },
    };
  },
  computed: {
    ...mapState({
      equipments: (state) => state.equipments,
      chosenPremise: (state) => state.premises.selected,
    }),
    rows() {
      return this.equipments.items.length;
    },
    selectedEquipment: function () {
      return this.chosenEquipment;
    },
    selectEquipmentsList: function () {
      return this.equipments.items
        ? Array.from(
            this.equipments.items.filter((item) => item.status),
            (equipment) => ({
              value: String(equipment.id),
              text: equipment.location
                ? `${equipment.type.name} (${equipment.location})`
                : `${equipment.type.name}`,
            })
          )
        : [];
    },
  },
  watch: {
    // Changed the premise to inventory
    chosenPremise: function (newVal, oldVal) {
      if (!this.premised) {
        return;
      }

      if (newVal === null) {
        // No more selected premise
        // console.log("[equipments] no more selected premise");
        this.clearAll();
      } else {
        // Newly selected premise
        // Get all the available logs for the selected premise
        // console.log("[equipments] fetching...");
        this.getAll(this.chosenPremise.name);
      }
    },
  },
  created() {
    console.log(
      `[equipments] Premised: ${this.premised}, chosen: '${
        this.chosenPremise && this.chosenPremise.name
      }'`
    );

    if (!this.premised || (this.premised && this.chosenPremise)) {
      // console.log("[equipments] getting all equipments");
      // Get all the available equipments
      this.getAll();
    }
  },
  methods: {
    ...mapActions("equipments", ["getAll", "clearAll"]),
    rowClass(item) {
      if (!item) return;
      if (item.status === false) return "table-danger";
    },
    alertRaise(item) {
      this.chosenEquipment = String(item.id);
      this.alertRaiseInfos.equipment = this.chosenEquipment;
      // this.alertRaiseInfos.title = `${item.type.name} (${item.location})`;
      this.alertRaiseInfos.title = this.$t("alert_title");
      this.$bvModal.show(this.alertRaiseInfos.id);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Known equipments",
    "alert_title": "Intervention request"
  },
  "fr": {
    "title": "Equipements connus",
    "alert_title": "Demande d'intervention"
  }
}
</i18n>
